import cn from 'classnames';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, } from 'react-router-dom';
import { decodeSourceName } from '../../utils/decodeURI';
import { useCurrentUser } from '../../contexts/CurrentUser';
import { Tooltip } from 'react-tooltip';

import { Paginator } from '../Paginator/Paginator';
import { Search } from '../Search/Search';
import styles from './SourcesTable.module.scss';
import { ReactComponent as WebIcon } from '../../assets/web.svg';
import { ReactComponent as FacebookIcon } from '../../assets/facebook.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/youtube.svg';
import { ReactComponent as VkontakteIcon } from '../../assets/vkontakte.svg';
import { ReactComponent as TwitterIcon } from '../../assets/twitter.svg';
import { ReactComponent as TelegramIcon } from '../../assets/telegram.svg';
import { ReactComponent as OkIcon } from '../../assets/odnoklassniki.svg';
import { ReactComponent as TikTokIcon } from '../../assets/tiktok.svg';
import { ReactComponent as VerifiedTwitter } from '../../pages/SourcePage/assets/verifiedTwitter.svg';
import { Loader } from '../Loader/Loader';

const platformIconsMapping = {
  TELEGRAM: <TelegramIcon />,
  VK: <VkontakteIcon />,
  TWITTER: <TwitterIcon />,
  WEB: <WebIcon />,
  FACEBOOK: <FacebookIcon/>,
  ADMIXER: <WebIcon />,
  OK: <OkIcon/>,
  YOUTUBE: <YoutubeIcon/>,
  TIKTOK: <TikTokIcon/>,
}

const statusMapping = {
  ACTIVE: "Active",
  PAUSED: "Paused",
  ARCHIVED: "Archived",
}

const sourceStatusClasses = {
  'ACTIVE': styles.active,
  'PAUSED': styles.paused,
  'ARCHIVED': styles.archived,
}

const sortingFieldNames = {
  ID: 'id',
  AUDIENCE: 'audience',
  ACTOR: 'name',
  PLATFORM: 'source_type',
  STATUS: 'status',
  URL: 'url',
  COUNTRY: 'country',
};

const formatNumberSignificant = d3.format('.3~s',);
const formatNumber = d3.format(",d");

export const SourcesTable = ({
  sources = [],
  onSelect,
  renderPrefixRow,
  searchQuery,
  withSearch,
  checkIsSelected,
  withHeader = true,
  headerClassName = '',
  rowClassName = '',
  state,
  onPageChange,
  selectAllCheckbox,
  controls,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentUser] = useCurrentUser();

  const cutString = (string) => {
    if (string.length > 30) {
      return string.slice(0, 27) + '...'
    } else {
      return string
    }
  }

  const mapCompromisedEntityTypes = {
    INVOLVED_IN_INFLUENCE_OPERATIONS: {title: t('Influence operations'), tooltip: t('Account has been involved in influence operations')},
    HACKED_OR_STOLEN: {title: t('Hackers / Hacktivists'), tooltip: t('Engaged or claimed to engage in cyber attacks or similar activity')},
    SANCTIONS: {title: t('Sanctions'), tooltip: t('Sources are listed in official sanctions list of EU (and member countries), US, UK, and other countries')},
    DISINFORMATION: {title: t('Disinformation'), tooltip: t('Sources are frequently sharing disinformative content, which was autodetected by Osavul AI')}
  }

  const mapBotEntityTypes = {
    'suspended_by_platform': 'Suspended by platform',
    'inauthentic_behavior': 'Inauthentic behavior',
    'immature_account':'Immature accounts',
  }

  let sortingQuery;

  if(state) {
    const sortingQuery = state.sorting?.fieldName
    ? `${state?.sorting?.isAscending ? '' : '-'}${sortingFieldNames[state.sorting?.fieldName]}`
    : null;
  }

  if(!sources) {
    return <Loader/>
  }

  if(sources?.objects.length === 0) {
    return <div className={styles.noData}>{t('There is no data to display')}</div>
  }

  return (
    <>
      <div className="card">
        {withHeader && (
          <div className={cn('card-header', headerClassName)}>
            <span className='title-total'>{t('Total actors:')} {formatNumber(sources.total)}</span>

            {withSearch && <Search className={styles.search} />}
          </div>
        )}
          <table
            className={cn('messages', styles.messages, {
              selectable: Boolean(onSelect),
            })}
          >
            <thead>
              <tr>
                {renderPrefixRow && selectAllCheckbox ? selectAllCheckbox() : renderPrefixRow ? <td></td> : ''}
                <td className={rowClassName}>{t('Actor')}</td>
                <td className={rowClassName}>{t('Audience')}</td>
                <td className={rowClassName}>{t('Origin country')}</td>
                {currentUser.is_super_admin || controls || renderPrefixRow ? <td className={styles.controls}></td> : ''}
              </tr>
            </thead>
            <tbody>
              {sources.objects.map((source) => {
                let followers_cnt = source?.audience;

                let botType = null;
                if(source?.suspended_by_platform === true) {
                  botType = mapBotEntityTypes['suspended_by_platform'];
                } else if(source?.inauthentic_behavior === true || source?.is_bot === true) {
                  botType = mapBotEntityTypes['inauthentic_behavior'];
                }else if(source?.immature_account === true) {
                  botType = mapBotEntityTypes['immature_account'];
                }
                return (
                  <tr
                    key={source.id}
                    className={
                      checkIsSelected && checkIsSelected(source)
                        ? 'selected'
                        : undefined
                    }
                  >
                    {renderPrefixRow?.(source)}

                    <td className={styles.mainInfo}>
                      <div className={styles.sourceNameWrapper}>
                        <div className={styles.platformWrapper}>
                          {platformIconsMapping[source.source_type]}
                        </div>
                        <div className={styles.sourceNameContainer}>
                          <span>
                            <Link to={`/sources/${source.id}`}>
                              {decodeSourceName(source.name)}
                            </Link>
                            {source?.is_verified ? (
                              <span className={styles.verified}>
                                <VerifiedTwitter />
                              </span>
                            ) : (
                              ''
                            )}
                          </span>
                          <div className={styles.sourceInfo}>
                            <span>#{source.id}</span>
                            <span>&#8226;</span>
                            <a
                              href={source?.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {cutString(decodeSourceName(source?.url))}
                            </a>
                            <span>&#8226;</span>
                            <span
                              className={sourceStatusClasses[source.status]}
                            >
                              {statusMapping[source.status]}
                            </span>
                          </div>
                          <div className={styles.tagsContainer}>
                            {source?.affiliations?.length > 0 ? (
                              <div className={styles.dualTag}>
                                <div className={styles.tagKey}>
                                  {t('State-affiliated')}
                                </div>
                                <div className={styles.tagValue}>
                                  {source?.affiliations[0]?.country?.code}
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                            {source?.discreditations?.length > 0 ? (
                              <div
                                className={styles.tag}
                                data-tooltip-id="discreditations-tag"
                                data-tooltip-content={
                                  mapCompromisedEntityTypes[
                                    source?.discreditations[0]?.entity_type
                                  ]?.tooltip
                                }
                              >
                                {
                                  mapCompromisedEntityTypes[
                                    source?.discreditations[0]?.entity_type
                                  ]?.title
                                }
                              </div>
                            ) : (
                              ''
                            )}

                            {botType === null ? (
                              ''
                            ) : (
                              <div className={styles.tag}>{botType}</div>
                            )}

                            {source?.tags?.length > 0 ? (
                              <span>
                                {source?.tags?.map((tag) => {
                                  let tagName = null;
                                  let item =
                                    tag?.level_0 !== null ? tag?.level_0 : null;

                                  if (
                                    localStorage.getItem('i18nextLng') ===
                                      'ua-UK' ||
                                    localStorage.getItem('i18nextLng') ===
                                      'ru-RU' ||
                                    localStorage.getItem('i18nextLng') ===
                                      'ru' ||
                                    localStorage.getItem('i18nextLng') === 'uk'
                                  ) {
                                    tagName = item?.name_uk;
                                  } else {
                                    tagName = item?.name_en;
                                  }

                                  return (
                                    <div className={styles.tag}>{tagName}</div>
                                  );
                                })}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>{formatNumberSignificant(followers_cnt)}</td>
                    <td>{source?.origin_country?.name}</td>
                    {controls ? (
                      <td className={styles.controls}>
                        <div className={styles.controlsContainer}>
                          {currentUser?.is_super_admin ? (
                            <Link
                              to={`/sources/${source.id}/edit`}
                              className="new-button"
                              data-tooltip-id="edit-source"
                              data-tooltip-content={t('Edit')}
                            >
                              <i className="lar la-edit"></i>
                            </Link>
                          ) : (
                            ''
                          )}
                          {controls && controls(source)}
                        </div>
                      </td>
                    ) : currentUser?.is_super_admin ? (
                      <td className={styles.controls}>
                        <Link
                          to={`/sources/${source.id}/edit`}
                          className="new-button"
                          data-tooltip-id="edit-source"
                          data-tooltip-content={t('Edit')}
                        >
                          <i className="lar la-edit"></i>
                        </Link>
                      </td>
                    ) : (
                      ''
                    )}
                  </tr>
                );
              })}
              <Tooltip id="edit-source" place="bottom"/>
              <Tooltip id="discreditations-tag" place="right"/>
            </tbody>
          </table>
      </div>
      <Paginator
        size={sources.size}
        page={sources.page}
        total={sources.total}
        onPageChange={(newPage) => {
          const urlParams = new URLSearchParams();

          if (searchQuery) {
            urlParams.set('q', searchQuery);
          }

          if (state) {
            if (state.searchQuery) {
              urlParams.set('q', searchQuery);
            }

            if (state.platforms && state.platforms.length) {
              state.platforms.forEach((platform) => {
                urlParams.append('source_types', platform);
              });
            }

            if (state.countries.length) {
              state.countries.forEach((country) => {
                urlParams.append('origin_country_ids', country);
              });
            }

            
            urlParams.set('audience_lte', state.audience_lte);
            urlParams.set('audience_gte', state.audience_gte);
            

            if(state.status.length) {
              state.status.forEach((status_) => {
                urlParams.append('statuses', status_);
              });
            }

            if (state?.sorting) {
              if (Array.isArray(state?.sorting?.fieldName)) {
                state?.sorting?.fieldName.forEach((element) => {
                  urlParams.append(
                    'sorting',
                    `${state?.sorting?.isAscending ? '' : '-'}${element}`,
                  );
                });
              } else {
                urlParams.set('sorting', sortingQuery);
              }
            }
            onPageChange(newPage)
          }
          urlParams.set('page', newPage)

          navigate(`?${urlParams.toString()}`);
        }}
      />
    </>
  );
};
