import { useTranslation } from 'react-i18next';
import { API } from '../../../API';
import Async from 'react-select/async';
import { useEffect, useState, useCallback, startTransition } from 'react';
import { ExcludeSwitch } from '../../SearchPage/Filter/ExcludeSwitch';
import { useCurrentUser } from '../../../contexts/CurrentUser';

export const AffiliationCountries = ({onChange, selected = [], onClose, excludeValue, onExcludeChange}) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [defaultOptions, setDefaultOptions] = useState([]);

  const [currentUser] = useCurrentUser();
  const workspaceConfig = currentUser?.workspace?.config;
  const featureFlags = workspaceConfig?.featureflags;
  const showExclusiveFilters = featureFlags?.includes('exclusive_filters');

  const handleInputChanged = (input, { action }) => {
    if (action === "set-value") {
      return;
    }

    setDefaultOptions([]);
    setSearchKey(input);
  };

  const selectAllOption = {value: 'all', label: t('Select all')};

  const getAllCountries = useCallback(() => {
    API.fetch('GET', `/API/v1/countries`).then((data) => {
      let options = data.objects.slice(0, 10).map(country => {return {value: country.id, label: country.name}});
      setCountries(options);
    });
  }, []);

  useEffect(getAllCountries, [getAllCountries]);

  useEffect(() => {
    if(selected.length > 0 && selected[0].value === 'all') {
      setSelectAll(true)
    }
  }, [])
  

  const getCountries = (inputValue, callback) => {
    return API.fetch('GET', `/API/v1/countries?q=${inputValue}`).then((data) => {
      let optionsList = data.objects
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((source) => {
          return {value: source.id, label: source.name};
        })
      setDefaultOptions(optionsList)
      callback(
        optionsList
      );
    });
  };

  let countriesPromiseTimeout;
  const countriesPromiseOptions = function (inputValue) {
    if (countriesPromiseTimeout !== null) {
      clearTimeout(countriesPromiseTimeout);
    }

    if (! inputValue) {
        return [];
    }

    return new Promise((resolve) => {
      countriesPromiseTimeout = setTimeout(() => {
        getCountries(inputValue, resolve);
      }, 1000);
    });
  }

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if(!selectAll === true) {
      onChange([selectAllOption]);
    } else {
      onChange([])
    }
    
  }



  if (!countries) return

  return(
    <div className='chooser affiliations-search-filter'>
      <div className='affiliation-filter-controls-container'>
        <div className='control' onClick={() => handleSelectAll()}>{selectAll ? t('Deselect all') : t('Select all')}</div>
        {showExclusiveFilters ? <ExcludeSwitch
          id='affiliation-country-switch'
          value={excludeValue}
          onChange={(bool) => onExcludeChange(bool)}
          color='red'
        /> : ''}
      </div>
      <Async
        className="select-filter"
        classNamePrefix="select-filter"
        cacheOptions
        defaultOptions={searchKey ? defaultOptions : countries}
        isMulti={true}
        loadOptions={countriesPromiseOptions}
        placeholder={t('Please enter at least 1 symbol')}
        noOptionsMessage={()=> t('Please enter at least 1 symbol')}
        loadingMessage={() => t('Loading...')}
        name="affiliation-countries"
        value={selected}
        onChange={(values) => {
          startTransition(() => onChange(values));
          setSearchKey("");
        }}
        onMenuClose={() => onClose()}
        isDisabled = {selectAll}
        closeMenuOnSelect={false}
        blurInputOnSelect={false}
        inputValue={searchKey}
        onInputChange={handleInputChanged}
      />
    </div>
  )
}